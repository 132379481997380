import React, { useReducer, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import axios from 'axios'

const InitializationContext = React.createContext();

const initialState = false

const reducer = (state, action) => {
    switch (action.type) {
        case 'setData':
            return {
                ...action.payload
            };
        case 'setUser':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

const InitializationProvider = ({ children }) => {
    const [dataApplication, dispatch] = useReducer(reducer, initialState);
    const [showTokenScreen, setShowTokenScreen] = useState(false)
    const [updateDataApplication, setUpdateDataApplication] = useState(0)
    const [blockScreen, setBlockScreen] = useState(false)
    const [fontLoaded, setFontLoaded] = useState(false)
    const [styleVersion, setStyleVersion] = useState(0)
    const [error, setError] = useState(false)
    const [isOpen, setIsOpen] = useState()
    const [miniLoader, setMiniLoader] = useState(false)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const [token, setToken] = useState(searchParams.get('token'))

    useEffect(() => {
        const startTime = Date.now(); // Record start time
        axios.post(`https://websocket-dev.flexinterativa.com.br/api/initialize/${token}`, {}, {
            withCredentials: true
        }).then(function (response) {
            console.log(response)
            let additionalDelay = 0
            if (response.data.message && !response.data.message.removeLogoFlex) {
                const fetchTime = Date.now() - startTime; // Calculate fetch duration
                additionalDelay = 1500
                additionalDelay = Math.max(additionalDelay - fetchTime, 0); // Adjust additional delay
            }
            if (response.data.error) {
                // Server is down
                setTimeout(() => {
                    setMiniLoader(false)
                    setShowTokenScreen(true)
                    setError('Servidor offline')
                }, additionalDelay);
            } else if (response.status === 203) {
                setTimeout(() => {
                    setMiniLoader(false)
                    setShowTokenScreen(true)
                }, 1500);
            } else if (response.status === 202) {
                // Server response
                setTimeout(() => {
                    setMiniLoader(false)
                    setShowTokenScreen(true)
                    setError(response.data.message)
                }, additionalDelay);
            } else {
                axios.defaults.baseURL = response.data.message.customizacao.server.eventosAPI
                dispatch({ type: 'setData', payload: response.data.message });
                setTimeout(() => {
                    setToken(response.data.message.token)
                }, additionalDelay);
            }
        }).catch(function (error) {
            console.log(error)
            setTimeout(() => {
                if (error.response && error.response.status === 401) {
                    setError("Token inválido")
                } else {
                    setError('Houve um erro no servidor')
                }
                setMiniLoader(false)
                setShowTokenScreen(true)
            }, 1500);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataApplication])

    return (
        <InitializationContext.Provider value={{
            dataApplication, dispatch, setUpdateDataApplication, token, setToken, error, setError, fontLoaded, setFontLoaded, showTokenScreen,
            blockScreen, setBlockScreen, setShowTokenScreen, miniLoader, setMiniLoader, styleVersion, setStyleVersion, isOpen, setIsOpen
        }}>
            {children}
        </InitializationContext.Provider>
    );

};

export {
    InitializationProvider,
    InitializationContext
};
