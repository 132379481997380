import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown, Image, Button, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faQrcode,
    faUser,
    faCircleInfo,
    faAward,
    faSignOut,
    faRankingStar
} from "@fortawesome/free-solid-svg-icons";
import { useGlobalState } from '../hooks/useInitialization.js';
import { useAppState } from '../hooks/useApp'
import useAuth from '../hooks/useAuth'
import defaultAvatar from '../assets/img/default-avatar-profile-picture-male-icon.png'
import { NotificationsDropdown } from '../pages/protected/Notifications'

const Navbar = () => {
    const { dataApplication } = useGlobalState();
    const { appState, handleExit } = useAppState();
    const { user } = useAuth()
    const { socket, dispatch, updateNotifications } = useAppState()
    const [showDropdowns, setShowDropdowns] = useState(false)
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
    const location = useLocation()
    const navigate = useNavigate();
    const imageLink = dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/"

    function handleResize() {
        if (window.innerWidth < 775) {
            setShowDropdowns(false)
        } else {
            setShowDropdowns(true)
        }
    }

    const handleNotificationDropdownToggle = (notOpen) => {
        if (showDropdowns) {
            if (notOpen) {
                socket.emit('editNotification', { type: 'viewed_at', })
            }
            setShowNotificationDropdown(notOpen);
        } else {
            socket.emit('editNotification', { type: 'viewed_at', })
            setShowNotificationDropdown(false);
            navigate(`/notificacoes${location.search}`)
        }
    };

    const handleProfileDropdownToggle = (notOpen) => {
        setShowProfileDropdown(notOpen);
        if (notOpen && user.type === 0) {
            socket.emit('getRanking');
        }
    };

    const UserInfo = ({ user }) => {
        if (user.type === 0) {
            return <div className="userInfo">
                {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                    if (field && user.json && field && user.json[field.inputID]) {
                        if (user.json && user.json[field.inputID]) {
                            if (index === 0) {
                                return (
                                    <p className="mb-0" key={'h5' + field.inputID}>{user.json[field.inputID].value}</p>
                                )
                            } else {
                                return (
                                    <small key={'small' + field.inputID}>{user.json[field.inputID].value}</small>
                                )
                            }
                        }
                    }
                    return null; // Handle cases where the field is not found (optional)
                })}
            </div>
        } else {
            return <div className="userInfo">
                <p className="mb-0">{user.email}</p>
                <Badge bg="primary">Coletor de lead</Badge>
            </div>
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        if (window.innerWidth < 775) {
            setShowDropdowns(false)
        } else {
            setShowDropdowns(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on('getNotifications', (response) => {
                dispatch({ type: 'notifications', payload: response });
                let counter = 0
                response.forEach(element => {
                    if (element.viewed_at === null) {
                        counter++
                    }
                });
                dispatch({ type: 'notificationsCounter', payload: counter > 0 ? counter : undefined });
            });

            socket.emit('getNotifications', { notRead: true });
            // Cleanup listener when component unmounts
            return () => {
                socket.off('getNotifications');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, updateNotifications]);

    return (
        <nav className="navbar">
            <div className="horizontally-centered">
                <img onClick={(() => navigate(`/${location.search}`))} src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.logoNav} alt="Logo" className="logo"></img>
            </div>
            <div className="horizontally-centered">
                {showDropdowns &&
                    <Button variant="primary" className={`iconButton me-2 ${location.pathname === '/qrcode' ? 'active' : ''}`} onClick={(() => navigate(`/qrcode${location.search}`))}>
                        <FontAwesomeIcon icon={faQrcode} size="lg" />
                    </Button>}
                <Dropdown className="d-inline me-2" variant="primary" show={showNotificationDropdown} onToggle={handleNotificationDropdownToggle}>
                    <Dropdown.Toggle className={`iconButton me-2 ${location.pathname === '/notificacoes' ? 'active' : ''}`}>
                        <FontAwesomeIcon icon={faBell} size="lg" />
                        {appState.notificationsCounter && <span className="notificationCounter">{appState.notificationsCounter}</span>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="notificationContainer">
                        <NotificationsDropdown setShowNotificationDropdown={setShowNotificationDropdown}></NotificationsDropdown>
                    </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown className="d-inline me-3" variant="primary">
                    <Dropdown.Toggle className={`iconButton ${location.pathname === '/mensagens' ? 'active' : ''}`}>
                        <FontAwesomeIcon icon={faMessage} size="lg" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(() => handleExit())}>Voltar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                {user && <Dropdown variant="primary" className="d-inline dropdown-perfil" show={showProfileDropdown} onToggle={handleProfileDropdownToggle}>
                    <Dropdown.Toggle>
                        <Image className="perfil" src={user.image !== null && user.image !== '' ? imageLink + user.image : defaultAvatar} roundedCircle ></Image>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="p-2">
                            <div className="userCard">
                                <Image className="perfilxxl" src={user.image !== null && user.image !== '' ? imageLink + user.image : defaultAvatar} roundedCircle ></Image>
                                <UserInfo user={user}></UserInfo>
                            </div>
                        </div>
                        {user.type === 0 && <div className="text-center pb-1">
                            <Badge onClick={(e) => { navigate(`/gamificacao${location.search}`); setShowProfileDropdown(false) }} type="button" bg={'primary'} className=""><FontAwesomeIcon icon={faRankingStar} size={'lg'} className="me-2" />{user.rank_position}º<FontAwesomeIcon icon={faAward} size={'lg'} className="ms-4 me-2" />{user.total_value} pts</Badge>
                        </div>}
                        <li><hr className="dropdown-divider" /></li>
                        <Dropdown.Item onClick={(() => navigate(`/perfil${location.search}`))}><FontAwesomeIcon className="me-2" icon={faUser} />Meu perfil</Dropdown.Item>
                        <Dropdown.Item onClick={(() => navigate(`/suporte${location.search}`))}><FontAwesomeIcon className="me-2" icon={faCircleInfo} />Ajuda & Suporte</Dropdown.Item>
                        <li><hr className="dropdown-divider" /></li>
                        <Dropdown.Item onClick={(() => handleExit())}><FontAwesomeIcon className="me-2" icon={faSignOut} />Sair</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </div>
        </nav >
    )
}

export default Navbar